<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("users.profile") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("users.updateProfile") }}
        </span>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'partnerAccountUsers' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="saveButtonDisabled" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          v-show="!saveButtonDisabled"
          class="btn btn-primary"
          @click="savePartner"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.password") }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend" @click="copyText(password)">
                <span class="input-group-text">
                  <i class="fal fa-copy cursor-pointer" />
                </span>
              </div>
              <input
                v-model="password"
                :type="[showPasswords['password'] ? 'text' : 'password']"
                class="form-control pr-0"
                :class="passwordValidationClass"
                @input="validateFields(3)"
              />
              <div
                class="input-group-append"
                @click="togglePassword('password')"
              >
                <span class="input-group-text">
                  <i
                    class="fal icon-lg cursor-pointer"
                    :class="[
                      showPasswords['password'] ? 'fa-eye-slash' : 'fa-eye'
                    ]"
                  ></i>
                </span>
              </div>
              <b-form-invalid-feedback v-if="!$v.password.minLength">
                {{ $t("validation.minChars", { chars: 8 }) }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.password.alphaNum">
                {{ $t("validation.alphaNum") }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.password.upLowCase">
                {{ $t("validation.upLowCase") }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.passwordConfirmation") }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div
                class="input-group-prepend"
                @click="copyText(passwordConfirmation)"
              >
                <span class="input-group-text">
                  <i class="fal fa-copy cursor-pointer" />
                </span>
              </div>
              <input
                v-model="passwordConfirmation"
                :type="[showPasswords['confirm'] ? 'text' : 'password']"
                class="form-control pr-0"
                :class="
                  !$v.passwordConfirmation.sameAs
                    ? 'is-invalid'
                    : password.length > 1
                    ? 'is-valid'
                    : ''
                "
                @input="validateFields(3)"
              />
              <div
                class="input-group-append"
                @click="togglePassword('confirm')"
              >
                <span class="input-group-text">
                  <i
                    class="fal icon-lg cursor-pointer"
                    :class="[
                      showPasswords['confirm'] ? 'fa-eye-slash' : 'fa-eye'
                    ]"
                  ></i>
                </span>
              </div>
              <b-form-invalid-feedback v-if="!$v.passwordConfirmation.sameAs">
                {{ $t("validation.samePassword") }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="
                  !$v.passwordConfirmation.required &&
                  $v.passwordConfirmation.sameAs
                "
              >
                {{ $t("validation.samePassword") }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Users from "@/components/Tenants/Users/users";

import { minLength, sameAs, helpers } from "vuelidate/lib/validators";
import { bus } from "@/main";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const alphaNum = helpers.regex(
  "alphaNum",
  /^(?=.*[a-zA-Z])(?=.*[0-9])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]+$/
);
const upLowCase = helpers.regex(
  "upLowCase",
  /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1}).*/
);

export default {
  name: "partnerAccountUsersDetailsAccessData",
  validations: {
    password: { minLength: minLength(8), alphaNum, upLowCase },
    passwordConfirmation: {
      sameAs: sameAs("password")
    }
  },
  data() {
    return {
      isBusy: false,
      saveButtonDisabled: false,
      user: {},
      password: "",
      passwordConfirmation: "",
      showPasswords: {
        password: false,
        confirm: false
      }
    };
  },
  computed: {
    passwordValidationClass() {
      if (this.password.length === 0) {
        return "";
      }
      if (
        !this.$v.password.minLength ||
        !this.$v.password.alphaNum ||
        !this.$v.password.upLowCase
      ) {
        return "is-invalid";
      }
      return "is-valid";
    }
  },
  mounted() {
    this.loadPartner();
  },
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword(passwordType) {
      this.showPasswords[passwordType] = !this.showPasswords[passwordType];
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonDisabled = this.$v.$invalid;
    },
    loadPartner() {
      let userId = this.$route.params.id;
      Users.get(userId)
        .then(response => {
          this.user = response.data.data;
          let breadCrumb = [
            {
              title: this.$t("menu.adminTenants"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/tenant/users/"
            }
          ];

          if (this.user.core_data && this.user.core_data.firstname) {
            breadCrumb.push({
              title: this.user.core_data.firstname,
              route: "/tenant/users/" + this.user.id + "/access-data"
            });
          }

          this.$store.dispatch(SET_BREADCRUMB, breadCrumb);

          this.validateFields();
        })
        .catch(e => {
          console.log(e);
        });
    },
    savePartner() {
      this.isBusy = true;

      let postData = {
        name: this.user.name,
        email: this.user.email,
        active: this.user.active,
        language_id: this.user.language.id
      };

      if (this.password) {
        postData.new_password = this.password;
      }

      Users.update(this.user.id, postData)
        .then(() => {
          this.loadPartner();
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
          bus.$emit("adminUpdated");
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
