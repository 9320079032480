<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.baseData") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("partner.updateBaseDataAndProfilePicture") }}
        </span>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'partnerAccountUsers' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="saveButtonDisabled" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          v-show="!saveButtonDisabled"
          class="btn btn-primary"
          @click="savePartner"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("partner.profilePicture") }}:
            </h3>

            <div class="form-group row align-items-center">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <ImageUploadCropper
                  aspect-ratio="1"
                  :default-image="partnerProfilePicture"
                  :upload-button-text="$t('partner.uploadProfilePicture')"
                  :crop-button-text="$t('partner.cropProfilePicture')"
                  :remove-button-text="$t('partner.removeProfilePicture')"
                  @cropMode="edit = $event"
                  @fileUpdated="files = $event"
                  @fileRemoved="fileRemoved()"
                >
                </ImageUploadCropper>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("partner.baseData") }}:
            </h3>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("partner.salutation") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  v-model="partner.core_data.salutation"
                  class="form-control"
                  :placeholder="$t('partner.enterSalutation')"
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("partner.title") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  v-model="partner.core_data.title"
                  class="form-control"
                  :placeholder="$t('partner.enterTitle')"
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("partner.firstName") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="adminFirstName"
                  v-model="partner.core_data.firstname"
                  :placeholder="$t('partner.enterFirstName')"
                  aria-describedby="adminFirstName-help adminFirstName-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.partner.core_data.firstname.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.partner.core_data.firstname.minLength
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.partner.core_data.firstname.required"
                  id="adminLastName-feedback"
                >
                  {{ $t("validation.required.firstname") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.partner.core_data.firstname.minLength"
                  id="adminLastName-feedback"
                >
                  {{ $t("validation.minChars", { chars: 3 }) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("partner.lastName") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="adminLastName"
                  v-model="partner.core_data.lastname"
                  :placeholder="$t('partner.enterLastName')"
                  aria-describedby="adminLastName-help adminLastName-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.partner.core_data.lastname.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.partner.core_data.lastname.minLength
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.partner.core_data.lastname.required"
                  id="adminLastName-feedback"
                >
                  {{ $t("validation.required.lastname") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.partner.core_data.lastname.minLength"
                  id="adminLastName-feedback"
                >
                  {{ $t("validation.minChars", { chars: 3 }) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("partner.email") }} <span class="text-danger">*</span>
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <b-form-input
                  id="adminEmail"
                  v-model="partner.email"
                  :placeholder="$t('partner.enterEmail')"
                  aria-describedby="adminEmail-help adminEmail-feedback"
                  trim
                  class="form-control"
                  :class="
                    !$v.partner.email.required
                      ? 'is-invalid'
                      : 'is-valid' && !$v.partner.email.email
                      ? 'is-invalid'
                      : 'is-valid' && !$v.partner.email.isUnique
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validateFields()"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!$v.partner.email.required"
                  id="adminEmail-feedback"
                >
                  {{ $t("validation.required.email") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.partner.email.email"
                  id="adminEmail-feedback"
                >
                  {{ $t("validation.email.email") }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="!$v.partner.email.isUnique"
                  id="adminEmail-feedback"
                >
                  {{ $t("validation.unique.email") }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("general.active") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-6">
                <span class="switch switch-sm">
                  <label>
                    <input v-model="partner.active" type="checkbox" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("users.language") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-4">
                <v-select
                  v-model="partner.language_id"
                  class="form-control"
                  :items="languageOptions"
                  item-text="label"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                />
                <b-form-text>{{ $t("users.selectLanguage") }}</b-form-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Users from "@/components/Tenants/Users/users";
import { email, required, minLength } from "vuelidate/lib/validators";
import { bus } from "@/main";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import Media from "@/components/Admins/Media/media";
import Languages from "@/components/General/Languages/languages";

export default {
  name: "partnerAccountPartnerDetailsInformation",
  components: {
    ImageUploadCropper
  },
  validations: {
    partner: {
      core_data: {
        firstname: { required, minLength: minLength(3) },
        lastname: { required, minLength: minLength(3) }
      },
      email: {
        email,
        required,
        isUnique(value) {
          return !this.currentUsers.includes(value);
        }
      }
    }
  },
  data() {
    return {
      isBusy: false,
      saveButtonDisabled: false,
      files: [],
      edit: false,
      partnerProfilePicture: "",
      partner: {
        name: "",
        email: "",
        active: false,
        language_id: "",
        profile_picture_id: "",
        core_data: {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        }
      },
      currentUsers: [],
      oldProfilePictureId: "",
      languageOptions: []
    };
  },
  mounted() {
    this.getLanguages();
    this.loadPartner();
  },
  methods: {
    fileRemoved() {
      this.files = [];
      this.partnerProfilePicture = false;
      this.partner.profile_picture_id = null;
    },
    validateFields() {
      this.$v.$touch();
      this.saveButtonDisabled = this.$v.$invalid;
    },
    getUsers() {
      let self = this;
      let params = {
        page: 1,
        size: 100
      };
      Users.getAll(params)
        .then(response => {
          response.data.data.forEach(async function (data) {
            if (self.partner.email !== data.email) {
              self.currentUsers.push(data.email);
            }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    loadPartner() {
      var partnerId = this.$route.params.id;
      Users.get(partnerId)
        .then(response => {
          this.partner = response.data.data;
          this.partner.core_data = JSON.parse(this.partner.core_data);
          this.partner.language_id = this.partner.language.id;
          this.oldProfilePictureId = this.partner.profile_picture_id;

          if (this.partner.core_data === null) {
            this.partner.core_data = {
              salutation: "",
              title: "",
              firstname: "",
              lastname: ""
            };
          }

          if (
            this.partner.profile_picture &&
            this.partner.profile_picture.length !== 0
          ) {
            this.partnerProfilePicture =
              "data:" +
              this.partner.profile_picture.mimeType +
              ";base64," +
              this.partner.profile_picture.base64;
          }

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.tenantUserAdministration"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/partner/users"
            },
            {
              title: this.partner.core_data.firstname,
              route: "/partner/users/" + this.partner.id
            }
          ]);

          this.getUsers();
          this.validateFields();
        })
        .catch(e => {
          console.log(e);
        });
    },
    deleteProfilePicture(mediaId) {
      if (mediaId !== null) {
        Media.delete(mediaId);
      }
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async savePartner() {
      this.isBusy = true;

      let postData = {
        name:
          this.partner.core_data.firstname +
          " " +
          this.partner.core_data.lastname,
        email: this.partner.email,
        active: this.partner.active ? 1 : 0,
        core_data: JSON.stringify(this.partner.core_data),
        language_id: this.partner.language.id
      };

      let form = new FormData();

      if (this.files.length) {
        form.append("profile_picture", this.files[0].file); // <------
      }

      Object.entries(postData).forEach(([key, value]) => {
        form.append(key, value);
      });

      Users.update(this.partner.id, form)
        .then(() => {
          if (this.oldProfilePictureId !== undefined) {
            this.deleteProfilePicture(this.oldProfilePictureId);
          }

          this.loadPartner();
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
          bus.$emit("adminUpdated");
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    }
  }
};
</script>
